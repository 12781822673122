import {
  isBefore,
  isAfter,
  format,
  differenceInCalendarDays,
  differenceInMilliseconds,
} from "date-fns";

/**
 * Reference: https://hyperfusedocs.sonic.game/introduction/when-can-i-purchase-the-hyperfuse-guardian-node-nft
 * Raffle Stage: Starts on September 16, 2024, at 1 PM UTC and lasts for 24 hours.
 * Whitelist Sale Stage: Starts on September 18, 2024, at 1 PM UTC and lasts for 24 hours.
 * Public Sale Stage: Starts on September 19, 2024, at 1 PM UTC.
 */
export const RAFFLE_STAGE_START_DATE = new Date("2024-09-16T13:00:00Z");
export const RAFFLE_STAGE_END_DATE = new Date("2024-09-17T13:00:00Z");
export const WHITELIST_START_DATE = new Date("2024-09-19T13:00:00Z");
export const WHITELIST_END_DATE = new Date("2024-09-20T12:59:59Z");
export const PUBLIC_SALE_DATE = new Date("2024-09-21T13:00:00Z");
// export const RAFFLE_STAGE_START_DATE = new Date("2024-09-01T13:00:00Z");
// export const RAFFLE_STAGE_END_DATE = new Date("2024-09-02T13:00:00Z");
// export const WHITELIST_START_DATE = new Date("2024-09-15T13:00:00Z");
// export const WHITELIST_END_DATE = new Date("2024-09-19T12:59:59Z");
// export const PUBLIC_SALE_DATE = new Date("2024-09-19T13:00:00Z");

const __PATTERN__ = "MMMM d, hh:mm a";

export const isWhitelistSeasonActive =
  isAfter(new Date(), WHITELIST_START_DATE) &&
  isBefore(new Date(), WHITELIST_END_DATE);
export const formattedStartDate = format(WHITELIST_START_DATE, __PATTERN__);
export const formattedEndDate = format(WHITELIST_END_DATE, __PATTERN__);
export const formattedPublicSaleDate = format(PUBLIC_SALE_DATE, __PATTERN__);

export function useWhitelistSeason() {
  const whitelistStartDate = WHITELIST_START_DATE;
  const whitelistEndDate = WHITELIST_END_DATE;

  const isActive =
    isAfter(new Date(), whitelistStartDate) &&
    isBefore(new Date(), whitelistEndDate);
  const formattedStartDate = format(whitelistStartDate, __PATTERN__);
  const formattedEndDate = format(whitelistEndDate, __PATTERN__);
  const formattedPublicSaleDate = format(PUBLIC_SALE_DATE, __PATTERN__);

  const isPublicSaleActive = isAfter(new Date(), PUBLIC_SALE_DATE);
  const isBeforePublicSale = isBefore(new Date(), PUBLIC_SALE_DATE);

  const isBeforeWhitelistStarted =
    isBefore(new Date(), whitelistStartDate) && !isActive;
  const isWhitelistStarted =
    isAfter(new Date(), whitelistStartDate) && isActive;
  const publicSaleRemainingDays = differenceInCalendarDays(
    PUBLIC_SALE_DATE,
    new Date()
  );

  const timeToWhitelistStart = differenceInMilliseconds(
    whitelistStartDate,
    new Date()
  );

  return {
    isActive,
    formattedStartDate,
    formattedEndDate,
    whitelistStartDate,
    whitelistEndDate,
    formattedPublicSaleDate,
    isPublicSaleActive,
    publicSaleRemainingDays,
    isBeforeWhitelistStarted,
    isBeforePublicSale,
    isWhitelistStarted,
    timeToWhitelistStart,
  };
}

export function useRaffleStageSeason() {
  const raffleStartDate = RAFFLE_STAGE_START_DATE;
  const raffleEndDate = RAFFLE_STAGE_END_DATE;

  const isRaffleStageActive =
    isAfter(new Date(), raffleStartDate) && isBefore(new Date(), raffleEndDate);
  const formattedStartDate = format(raffleStartDate, __PATTERN__);
  const formattedEndDate = format(raffleEndDate, __PATTERN__);

  const isBeforeRaffleStageStarted = isBefore(new Date(), raffleStartDate);
  const isRaffleStageStarted = isAfter(new Date(), raffleStartDate);
  const isRaffleStageEnded = isAfter(new Date(), raffleEndDate);

  const timeToRaffleStart = differenceInMilliseconds(
    raffleStartDate,
    new Date()
  );

  return {
    isActive: isRaffleStageActive,
    formattedStartDate,
    formattedEndDate,
    raffleStartDate,
    raffleEndDate,
    isRaffleStageActive,
    isRaffleStageStarted,
    isBeforeRaffleStageStarted,
    timeToRaffleStart,
    isRaffleStageEnded,
  };
}

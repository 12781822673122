import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

export function useMobile() {
  const { $isMobile } = useNuxtApp();
  const isMobile = $isMobile();

  return {
    isMobile,
  };
}

export function useMobileViewport() {
  const breakpoints = useBreakpoints(breakpointsTailwind);
  const isMobileViewport = breakpoints.smaller("sm");

  return {
    isMobileViewport,
  };
}

export const head = {
  title: "HyperFuse Guardian Node Sale",
  meta: [
    {
      name: "description",
      content:
        "This is the HyperFuse Guardian Node sales page. A HyperFuse Guardian Node within Sonic's HyperGrid Framework is designed to detect and alert the network of any discrepancies in transactions or state transitions.",
    },
    { property: "og:url", content: "https://node.sonic.game" },
    { property: "og:type", content: "website" },
    { property: "og:title", content: "HyperFuse Guardian Node" },
    {
      property: "og:description",
      content:
        "This is the HyperFuse Guardian Node sales page. A HyperFuse Guardian Node within Sonic's HyperGrid Framework is designed to detect and alert the network of any discrepancies in transactions or state transitions.",
    },
    {
      property: "og:image",
      content: "https://node.sonic.game/img/content.jpg",
    },
    { name: "twitter:card", content: "summary_large_image" },
    { property: "twitter:domain", content: "node.sonic.game" },
    { property: "twitter:url", content: "https://node.sonic.game" },
    { name: "twitter:title", content: "HyperFuse Guardian Node" },
    {
      name: "twitter:description",
      content:
        "This is the HyperFuse Guardian Node sales page. A HyperFuse Guardian Node within Sonic's HyperGrid Framework is designed to detect and alert the network of any discrepancies in transactions or state transitions.",
    },
    {
      name: "twitter:image",
      content: "https://node.sonic.game/img/content.jpg",
    },
  ],
};

import { useQuery } from "@tanstack/vue-query";
import { api } from "../api";
import { keys, type ResponseType } from "../api.keys";
import type { WhitelistTiers } from "../types";
import { useWalletsStore } from "~/stores/wallets";

export async function getWhitelistTiers(wallet?: string) {
  const response = await api.get<ResponseType<WhitelistTiers>>(
    "/node/whitelist",
    {
      params: {
        wallet,
      },
    }
  );

  return response.data;
}

export function useWhitelistTiers() {
  const wallet = useWalletsStore();
  const address = wallet.wallet?.publicKey?.toBase58?.();

  return useQuery({
    queryKey: keys.whitelistTiers(address),
    queryFn: () => getWhitelistTiers(address),
    enabled: address !== "",
  });
}
